import {Inject, Injectable} from '@angular/core'
import {Language} from './language'
import {BehaviorSubject, Observable} from 'rxjs'
import {DOCUMENT} from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private static readonly defaultLanguageSupport = Object.values(Language)
  private static readonly _supportedLanguages = new BehaviorSubject<Language[]>([
    ...LanguageService.defaultLanguageSupport,
  ])

  constructor(@Inject(DOCUMENT) private document: Document) {}

  static allSupportedLanguages(): Language[] {
    return this._supportedLanguages.getValue()
  }

  supportedLanguages(): Language[] {
    return LanguageService._supportedLanguages.getValue()
  }

  get supportedLanguages$(): Observable<Language[]> {
    return LanguageService._supportedLanguages.asObservable()
  }

  setDocumentLanguage(language: string) {
    if (this.document.documentElement.lang !== language) {
      this.document.documentElement.lang = language
    }
  }
}
